import React, { useState } from "react";
import ErrorBoundary from "../../../ErrorBoundary";
import { Input } from "reactstrap";

const CommnetsCellRender = ({ rowData, columnName, updateRowValue }) => {
  const [comment, setComment] = useState(rowData[columnName] || "");

  const handlerChange = (event) => {
    const { value } = event.target;
    setComment(value);
    updateRowValue(value, columnName);
  };

  return (
    <div>
      <ErrorBoundary>
        <Input
          type="textarea"
          name={columnName}
          id={columnName}
          maxLength={100}
          value={comment}
          onChange={handlerChange}
        />
      </ErrorBoundary>
    </div>
  );
};

export default CommnetsCellRender;