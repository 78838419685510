import React, { useEffect, useState } from 'react';
import ReactStrapTable from "components/reactStrapTable";
import { cellRendererCheck, currencyFormat, cellnumberFormat } from "utils/columns";
import Loader from 'components/Loader';
import Tooltip from 'react-tooltip-lite';

const PharmacyDetails = (props) => {

    const {
        tabledata,
        totalSize,
        onPageChange,
        apiFilter,
        refetch,
        onSearchColumnFilters,
        handleExport,
        loading
    } = props;

    const [reset, setReset] = useState(false);
    const [filters, setFilters] = useState(apiFilter);

    useEffect(() => {
        setFilters(apiFilter);
    }, [apiFilter]);

    useEffect(() => {
        setReset((pre) => !pre);
        setFilters({
            currentPage: 1,
            pageSize: 10,
            orderBy: '',
            order: '',
            filters: [],
        });
    }, []);

    const cellrendertooltipclaim = (row, value) => {

        if (row.claimProcessing === 0 && row.switchFeeNotes != null) {
            return (
                <Tooltip content={(
                    <div className='tip-list-info'>
                        <p>{row.switchFeeNotes}</p>
                    </div>
                )}
                    direction="down"
                    tagName="span"
                    className="target"
                    tipContentClassName=""
                    zIndex={9999}>
                    <span
                        className='cursor-pointer text-decoration-underline'
                    >
                        {currencyFormat(row.claimProcessing)}
                    </span>
                </Tooltip>)
        }
        else if (row.claimProcessing != 0 && row.switchFeeNotes == null) {
            return (
                currencyFormat(row.claimProcessing)
            )
        }
        else if (row.claimProcessing != 0 && row.switchFeeNotes != null) {
            return (
                <Tooltip content={(
                    <div className='tip-list-info'>
                        <p>{row.switchFeeNotes}</p>
                    </div>
                )}
                    direction="down"
                    tagName="span"
                    className="target"
                    tipContentClassName=""
                    zIndex={9999}>
                    <span
                        className='cursor-pointer text-decoration-underline'
                    >
                        {currencyFormat(row.claimProcessing)}
                    </span>
                </Tooltip>)
        }
        else {
            if (!value) return '-';
            return value;
        };
    };

    const cellrendertooltippharmacyAdj = (row, value) => {

        if (row.miscellaneousCharges === 0 && row.pharmaForceAdjustmentNotes != null) {
            return (
                <Tooltip content={(
                    <div className='tip-list-info'>
                        <p>{row.pharmaForceAdjustmentNotes}</p>
                    </div>
                )}
                    direction="down"
                    tagName="span"
                    className="target"
                    tipContentClassName=""
                    zIndex={9999}>
                    <span
                        className='cursor-pointer text-decoration-underline'
                    >
                        {currencyFormat(row.miscellaneousCharges)}
                    </span>
                </Tooltip>)
        }
        else if (row.miscellaneousCharges != 0 && row.pharmaForceAdjustmentNotes == null) {
            return (
                currencyFormat(row.miscellaneousCharges)
            )
        }
        else if (row.miscellaneousCharges != 0 && row.pharmaForceAdjustmentNotes != null) {
            return (
                <Tooltip content={(
                    <div className='tip-list-info'>
                        <p>{row.pharmaForceAdjustmentNotes}</p>
                    </div>
                )}
                    direction="down"
                    tagName="span"
                    className="target"
                    tipContentClassName=""
                    zIndex={9999}>
                    <span
                        className='cursor-pointer text-decoration-underline'
                    >
                        {currencyFormat(row.miscellaneousCharges)}
                    </span>
                </Tooltip>)
        }
        else {
            if (!value) return '-';
            return value;
        };
    };

    const cellrendertooltippfTPAfee = (row, value) => {

        if (row.pharmaForceTPAFee === 0 && row.pharmaForceMissingFeeNotes != null) {
            return (
                <Tooltip content={(
                    <div className='tip-list-info'>
                        <p>{row.pharmaForceMissingFeeNotes}</p>
                    </div>
                )}
                    direction="down"
                    tagName="span"
                    className="target"
                    tipContentClassName=""
                    zIndex={9999}>
                    <span
                        className='cursor-pointer text-decoration-underline'
                    >
                        {currencyFormat(row.pharmaForceTPAFee)}
                    </span>
                </Tooltip>)
        }
        else if (row.pharmaForceTPAFee != 0 && row.pharmaForceMissingFeeNotes == null) {
            return (
                currencyFormat(row.pharmaForceTPAFee)
            )
        }
        else if (row.pharmaForceTPAFee != 0 && row.pharmaForceMissingFeeNotes != null) {
            return (
                <Tooltip content={(
                    <div className='tip-list-info'>
                        <p>{row.pharmaForceMissingFeeNotes}</p>
                    </div>
                )}
                    direction="down"
                    tagName="span"
                    className="target"
                    tipContentClassName=""
                    zIndex={9999}>
                    <span
                        className='cursor-pointer text-decoration-underline'
                    >
                        {currencyFormat(row.pharmaForceTPAFee)}
                    </span>
                </Tooltip>)
        }
        else {
            if (!value) return '-';
            return value;
        };
    };

    const cellrendertooltipgateway = (row, value) => {

        if (row.gatewayCharge === 0 && row.gatewayMissingFeeNotes != null) {
            return (
                <Tooltip content={(
                    <div className='tip-list-info'>
                        <p>{row.gatewayMissingFeeNotes}</p>
                    </div>
                )}
                    direction="down"
                    tagName="span"
                    className="target"
                    tipContentClassName=""
                    zIndex={9999}>
                    <span
                        className='cursor-pointer text-decoration-underline'
                    >
                        {currencyFormat(row.gatewayCharge)}
                    </span>
                </Tooltip>)
        }
        else if (row.gatewayCharge != 0 && row.gatewayMissingFeeNotes == null) {
            return (
                currencyFormat(row.gatewayCharge)
            )
        }
        else if (row.gatewayCharge != 0 && row.gatewayMissingFeeNotes != null) {
            return (
                <Tooltip content={(
                    <div className='tip-list-info'>
                        <p>{row.gatewayMissingFeeNotes}</p>
                    </div>
                )}
                    direction="down"
                    tagName="span"
                    className="target"
                    tipContentClassName=""
                    zIndex={9999}>
                    <span
                        className='cursor-pointer text-decoration-underline'
                    >
                        {currencyFormat(row.gatewayCharge)}
                    </span>
                </Tooltip>)
        }
        else {
            if (!value) return '-';
            return value;
        };
    };

    const onHandleSort = (filter, hasColFilter) => {
        setFilters(filter);
        if (hasColFilter) {
            onPageChange(filter, true);
        } else {
            onPageChange(filter);
        };
    };

    const handleFilterCancel = () => {
        refetch(true);
    };

    const onPageSizeChange = (filter, hasColFilter, colChangedFilter) => {
        if (hasColFilter) {
            setFilters(filter);
            let colFilterOn = { ...filter, filters: colChangedFilter };
            return onPageChange(colFilterOn, true);
        };
        setFilters(filter);
        onPageChange(filter);
    };

    const onHandlePageChange = (filter, hasColFilter, colChangedFilter) => {
        if (hasColFilter) {
            setFilters(filter);
            let colFilterOn = { ...filter, filters: colChangedFilter };
            onPageChange(colFilterOn, true);
        } else {
            setFilters(filter);
            onPageChange(filter);
        };
    };

    const handleSearchColumnFilters = (colFilter) => {

        if (onSearchColumnFilters) {
            const filter = { ...filters, filters: colFilter, currentPage: 1 };
            onSearchColumnFilters(filter);
            setFilters(filter);
        };
    };

    let maincol = [
        {
            dataField: 'edit',
            text: 'Options',
            //cellRenderer: (row) => renderButtons(row),
            width: 80,
            show: true,
            disabled: true,
            hideColFilter: true,
            action: true
        },
        {
            dataField: 'phName',
            text: 'Pharmacy',
            width: 170,
            sort: true,
            show: true,
            inputType: 'text',
            cellRenderer: (row) => cellRendererCheck(row.phName)
        },
        {
            dataField: 'totalClaimsReceived',
            text: 'Total Claims Received',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            cellRenderer: (row) => cellnumberFormat(row.totalClaimsReceived)
        },
        {
            dataField: 'capturedClaimCount',
            text: 'Captured Claim Count',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            cellRenderer: (row) => cellnumberFormat(row.capturedClaimCount)
        },
        {
            dataField: 'totalPaymentsReceived',
            text: 'Total Payments Received',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            cellRenderer: (row) => currencyFormat(row.totalPaymentsReceived)
        },
        {
            dataField: 'pharmaForceTPAFee',
            text: 'PharmaForce TPA Fee',
            width: 110,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            cellRenderer: (row) => cellrendertooltippfTPAfee(row)
        },
        {
            dataField: 'claimProcessing',
            text: 'Claim Processing',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            cellRenderer: (row) => cellrendertooltipclaim(row)
        },
        {
            dataField: 'transactionCharge',
            text: 'Transaction Charge',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            cellRenderer: (row) => currencyFormat(row.transactionCharge)
        },
        {
            dataField: 'gatewayCharge',
            text: 'Gateway Charge',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            cellRenderer: (row) => cellrendertooltipgateway(row)
        },
        {
            dataField: 'miscellaneousCharges',
            text: 'Pharmacy Adjustments',
            width: 120,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            cellRenderer: (row) => cellrendertooltippharmacyAdj(row)
        },
        {
            dataField: 'totalCharges',
            text: 'Total Charges',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            cellRenderer: (row) => currencyFormat(row.totalCharges)
        },
        {
            dataField: 'total340BNetRemitDue',
            text: 'Total 340B Net Remit Due',
            width: 100,
            show: true,
            sort: true,
            inputType: 'number',
            hideColFilter: true,
            cellRenderer: (row) => currencyFormat(row.total340BNetRemitDue)
        }
    ];

    let data = [];

    return (
        <>
            <div
                className='table-card scroll-table position-relative border-0  rounded pt-0'
                fixed-height={data.length > 0 ? 'fixed-height' : 'default'}>
                {loading && <Loader />}
                <ReactStrapTable
                    id='pharmacydetails'
                    fixedColumn
                    hasPagination
                    data={tabledata}
                    columns={maincol}
                    filters={filters}
                    totalRecords={totalSize}
                    pageSize={filters.pageSize}
                    currentPage={filters.currentPage}
                    handleSort={onHandleSort}
                    onPageSizeChange={onPageSizeChange}
                    onPageChange={onHandlePageChange}
                    reset={reset}
                    onFilterCancel={handleFilterCancel}
                    onSearchColumnFilters={handleSearchColumnFilters}
                    onExport={handleExport}
                />
            </div>
        </>
    );
};

export default PharmacyDetails;