import React, { useEffect, useState } from 'react';
import ErrorBoundary from '../../../ErrorBoundary';
import moment from 'moment';
import { InputGroup, InputGroupText } from 'reactstrap';
import { Field, Formik } from 'formik';
import { dateFormate } from 'utils/columns';
import FormikDatePicker from 'components/common/Fields/FormikDatePicker';
import Calendar from 'assets/icon/calendar-icon.svg';
let hasDatePopupOpen = false;
const InputDateCellRender = ({
    rowData,
    columnName,
    updateRowValue,
    required = false,
    hasTouched = false,
}) => {
    const subProps = {};
    const value = rowData[columnName] || '';
    const [text, setText] = useState(null);


    useEffect(() => {
        if (value !== '') {
            var newDate = dateFormate(new Date(value));
            setText(newDate);
        }
    }, []);

    const handlerChange = (startDate) => {
        let stDate = dateFormate(new Date(startDate));
        setText(stDate);
        updateRowValue(stDate, columnName);
    };

    const handleMouseLeave = () => {
        hasDatePopupOpen = false;
    };

    const handleDateClick = (id) => {
        if (hasDatePopupOpen) {
            hasDatePopupOpen = false;
            return;
        }
        const calendarButton = document.getElementById(id);
        if (calendarButton) {
            calendarButton.click();
            hasDatePopupOpen = true;
        }
    };

    return (
        <div>
            <ErrorBoundary>
                <Formik>
                    <InputGroup onMouseLeave={handleMouseLeave}>
                        <Field
                            type='text'
                            id={columnName}
                            name={columnName}
                            autoComplete='off'
                            placeholder='Select a date'
                            value={text}
                            component={FormikDatePicker}
                            onChange={handlerChange}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                        <InputGroupText
                            onClick={() => handleDateClick(columnName)}
                        >
                            <img src={Calendar} alt='' />
                        </InputGroupText>
                    </InputGroup>
                </Formik>
            </ErrorBoundary>
        </div>
    );
};

export default InputDateCellRender;