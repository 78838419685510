import React, { useState } from "react";
import ErrorBoundary from "../../../ErrorBoundary";
import { Input, } from "reactstrap";

const CheckBoxCellRender = ({ rowData, columnName, updateRowValue }) => {
  const value = rowData[columnName] === "Y" ? true : false;
  const [check, setCheck] = useState(value)

  const handlerChange = (event) => {
    const { checked } = event.target;
    setCheck(checked);
    updateRowValue(checked ? "Y" : "N", columnName);
  }

  return (
    <div>
      <ErrorBoundary>
        <Input type="checkbox" name={columnName} checked={check} onChange={handlerChange} />
      </ErrorBoundary>
    </div>
  );
};

export default CheckBoxCellRender;