import React, { useState, useEffect } from 'react';
import useAxios from 'hooks/useAxios';
import ErrorBoundary from '../../../ErrorBoundary';
import { Input } from 'reactstrap';

const StatusCellRender = ({
  rowData,
  updateRowValue,
  hasTouched = false,
  required = false,
}) => {
  const API = useAxios();
  const subProps = {};
  const { claimStatusID } = rowData;
  const [statusList, setStatusList] = useState([]);
  const [claimId, setClaimId] = useState(claimStatusID);
  const fetchData = async () => {
    try {
      const { data } = await API.get(
        'RCMConfig/TypeStatus/AllowedClaimStatus/' + claimStatusID,
        {
          hasDefaultURL: true,
        }
      );
      setStatusList(data);
      if (data.length === 1) {
        const { id } = data[0];
        setClaimId(id);
        updateRowValue(id, 'claimStatusID');
      };
    } catch (error) {
      console.log(error.message);
    };
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlerChange = (event) => {
    const { value } = event.target;
    setClaimId(value);
    updateRowValue(value, 'claimStatusID');
  };

  if (required && hasTouched) {
    subProps.title = 'Status is required';
  };

  const disabled = statusList.length === 1;

  return (
    <div>
      <ErrorBoundary>
        <Input
          type='select'
          onChange={(e) => handlerChange(e)}
          bsSize='sm'
          className={`d-inline-block ${required ? 'required' : ''}`}
          value={claimId}
          {...subProps}
          disabled={disabled}
        >
          <option value={''}>--Select--</option>
          {statusList.map((status) => {
            const { id, description } = status;
            return (
              <option key={id} value={id}>
                {description}
              </option>
            );
          })}
        </Input>
      </ErrorBoundary>
    </div>
  );
};

export default StatusCellRender;