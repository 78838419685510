import React from "react";
import { Button } from "reactstrap";
import HoverImage from "react-hover-image/build";
import SaveIcon from "assets/icon/icon-icon-options-order-complete.svg";
import SaveHoverIcon from "assets/icon/icon-icon-options-order-complete-hover.svg";
import CancleIcon from "assets/icon/icon-icon-options-not-eligible.svg";
import CancleHoverIcon from "assets/icon/icon-icon-options-not-eligible-hover.svg";
import EditIcon from "assets/icon/icon-icon-options-edit.svg";
import EditHoverIcon from "assets/icon/icon-icon-options-edit_hover.svg";

const CellOptions = (props) => {
  const { row, onEdit, onSave } = props;
  if (row.isEdit) {
    return (
      <div className="d-flex align-items-center">
        <Button
          className="text-primary p-0 text-decoration-none"
          color="link"
          onClick={onSave}
        >
          <HoverImage
            key="SaveIcon"
            src={SaveIcon}
            hoverSrc={SaveHoverIcon}
            alt=""
          />
        </Button>
        <Button
          className="text-primary p-0 text-decoration-none"
          color="link"
          onClick={() => onEdit(row, false)}
        >
          <HoverImage src={CancleIcon} hoverSrc={CancleHoverIcon} alt="" />
        </Button>
      </div>
    );
  } else {
    return (
      <div className="d-flex align-items-center">
        <Button
          className="text-primary p-0 text-decoration-none"
          color="link"
          onClick={() => onEdit(row)}
        >
          <HoverImage src={EditIcon} hoverSrc={EditHoverIcon} alt="" />
        </Button>
      </div>
    );
  };
};

export default CellOptions;