import React, { useEffect, useState } from 'react';
import ReactStrapTable from 'components/reactStrapTable';
import { Row, Col, CardBody, Container, Card, Button, FormGroup, Label, Input } from 'reactstrap';
import { Formik } from 'formik';
import { cellRendererCheck, dateFormate } from 'utils/columns';
import CellEditRender from 'components/reactStrapTable/components/CellEditRender';
import useAxios from 'hooks/useAxios';
import CellOptions from 'components/reactStrapTable/components/CellOptions';
import Loader from 'components/Loader';

let rowForEdit = {};
let Notesdata = [];
let textvalue = true;

let initialFiltervalue = {
    currentPage: 1,
    pageSize: 10,
    orderBy: 'NoteType',
    order: 'desc',
    filters: [],
};

const InvoiceNotes = (props) => {

    const API = useAxios();
    const {
        tabledata,
        CoveredEntity,
        Cycle,
        year,
        month,
        ceid,
        fetchInvoicesNoteList,
        loading
    } = props;

    const [show1, setShow] = React.useState(true);
    const [show2, setShow2] = React.useState(true);
    const [showuser, setShowuser] = React.useState(false);
    const [NoteType, setNoteType] = useState(0);
    const [NoteText, setNoteText] = useState("");

    const handleNoteText = ({ target: { value } }) => {
        setNoteText(value);
    };

    const { profile } =
        JSON.parse(sessionStorage.getItem('pharmaforce')) || {};
    const { loginId, roleId } = profile || {};

    useEffect(() => {
        AddNotetype();
    }, []);

    const GetNotesdata = async () => {

        Notesdata = [];
        try {
            const { data } = await API.get(`/fm/notetypes/${ceid}/${year}/${month}`, {
                hasDefaultURL: false,
            });
            if (data) {
                for (var x in data) {
                    Notesdata.push({ "value": data[x].noteTypeID, "label": data[x].notetype });
                };
                textvalue = true;
            }
        } catch (err) {
        };
    };

    const editRow = (rowData, isEdit = true) => {

        setShow(!show1);
        let tempData = [...tabledata];
        tempData = tempData.map((row) => {
            row.isEdit = false;
            if (row.noteType === rowData?.noteType && isEdit) {
                row.isEdit = isEdit;
                rowForEdit = row;
            }
            return row;
        });
    };

    const fetchinvoiceNotetype = async (e) => {

        if (e.target.value == 0) {
            textvalue = true;
            setNoteType(e.target.value)
        }
        else {
            textvalue = false;
            setNoteType(e.target.value)
        };
    };

    const AddNotetype = async (e) => {

        if (roleId == "4") {
            setShowuser(!showuser)
        }
    };

    const saveNote = async () => {

        if (NoteType != 0 && NoteText != "") {
            var loginID = loginId;
            setShow(!show1)

            const { data } = await API.post(`/fm/savecefmnotes/${NoteType}/${NoteText}/${ceid}/${year}/${month}`, {
                hasDefaultURL: false,
            });
            if (data) {
                setNoteType(0);
                setNoteText("");
                fetchInvoicesNoteList(initialFiltervalue);
                GetNotesdata();
            };
        };
    };

    const updateNote = async () => {
        const tempRow = { ...rowForEdit };
        var PFCEInvoiceID = tempRow.pfceInvoiceID;
        var NoteText = tempRow.note
        var NoteTypeName = tempRow.noteType;
        if (NoteText != "") {
            const { data } = await API.post(`/fm/updatecefmnotes/${PFCEInvoiceID}/${NoteTypeName}/${NoteText}`, {
                hasDefaultURL: false,
            });

            if (data) {
                fetchInvoicesNoteList(initialFiltervalue);
            };
        };
    };

    const cellRenderEdit = (row) => {
        if (showuser == false)
            return <CellOptions row={row} onEdit={editRow} onSave={updateNote} />;
    };

    const updateRowValue = (value, col) => {
        const tempRow = { ...rowForEdit };
        tempRow[col] = value;
        rowForEdit = tempRow;
    };

    const formatter = (row, col, formatterType, alwaysEditableCheckbox) => {
        return (
            <CellEditRender
                row={row}
                col={col}
                formatterType={formatterType}
                onChange={updateRowValue}
                alwaysEditableCheckbox={alwaysEditableCheckbox}
            />
        );
    };

    const maincol = [
        {
            dataField: 'edit',
            text: 'Options',
            width: 80,
            cellRenderer: (row) => cellRenderEdit(row),
            show: true,
            hideColFilter: true,
            action: true
        },
        {
            dataField: 'Carry Over Notes',
            text: 'Notes Type',
            sort: false,
            width: 150,
            show: true,
            cellRenderer: (rowData) => cellRendererCheck(rowData.noteType)
        },
        {
            dataField: 'note',
            text: 'Notes',
            sort: false,
            width: 250,
            show: true,
            edit: true,
            inputType: 'text',
            hideColFilter: true,
            cellRenderer: (row, col) => formatter(row, col, "textarea")
        },
        {
            dataField: 'modifiedDate',
            text: 'Modified Date',
            sort: false,
            width: 90,
            show: true,
            inputType: 'date',
            cellRenderer: (row, col) => dateFormate(row.modifiedDate)
        },
        {
            dataField: 'modifiedBy',
            text: 'Modified By',
            sort: false,
            width: 140,
            show: true,
            hideColFilter: true,
            inputType: 'text',
            cellRenderer: (row, col) => cellRendererCheck(row.modifiedBy)
        }
    ];

    return (
        <Container fluid className='my-0'>
            <Card className='border-0 shadow-top'>
                <CardBody className='section-devider-border'>
                    <div>
                        <Formik>
                            <div>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Col className='pe-3'>
                                                <p className='f-14 mb-0'>
                                                    Covered Entity:
                                                    <b className='d-inline-block ps-2 font-weight-500'>
                                                        <span>{CoveredEntity}</span>
                                                    </b>
                                                </p>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Col className='pe-3'>
                                                <p className='f-14 mb-0'>
                                                    Cycle:
                                                    <b className='d-inline-block ps-2 font-weight-500'>
                                                        <span>{Cycle}</span>
                                                    </b>
                                                </p>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row hidden={showuser}>
                                    <Col md={3} sm={6} xs={6} hidden={show2}>
                                        {' '}
                                        <FormGroup>
                                            <Label for='NotestypeID'>Notes Type</Label>
                                            <Input
                                                type='select'
                                                onChange={(e) => {
                                                    fetchinvoiceNotetype(e);
                                                }}
                                                required
                                                value={NoteType}
                                            >
                                                <option value='0'>Select...</option>
                                                {Notesdata.map((status) => {
                                                    const { value, label } = status;
                                                    return (
                                                        <option key={value} value={value}>
                                                            {label}
                                                        </option>
                                                    );
                                                })}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} sm={6} xs={6} hidden={show2}>
                                        {' '}
                                        <FormGroup>
                                            <Label for='NotesID'>Notes</Label>
                                            <Input
                                                type='textarea'
                                                maxLength={100}
                                                style={{ maxHeight: "50px" }}
                                                onChange={(e) => {
                                                    handleNoteText(e);
                                                }}
                                                value={NoteText}
                                            >
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col hidden={show2}>
                                        <FormGroup>
                                            <br />
                                            <Button
                                                color="primary"
                                                className="ms-3"
                                                style={{ float: "inline-end" }}
                                                onClick={() => saveNote()
                                                }
                                                disabled={textvalue}>
                                                {"Save"}
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                    <Col hidden={show2}>
                                        <FormGroup>
                                            <br />
                                            <Button
                                                color="primary"
                                                className="ms-3"
                                                style={{ float: "inline-end" }}
                                                onClick={() => setShow2(!show2)
                                                }
                                            >
                                                {"Cancel"}
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup hidden={!show2}>
                                            <br />
                                            <Button
                                                color="primary"
                                                className="ms-3"
                                                style={{ float: "inline-end" }}
                                                onClick={() => {
                                                    setShow2(!show2), GetNotesdata()
                                                    setNoteType(0),
                                                        setNoteText(""),
                                                        textvalue = true
                                                }
                                                }>
                                                {"Add"}
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <div
                                            className='table-card scroll-table position-relative border-0  rounded pt-0'
                                            fixed-height={tabledata.length > 0 ? 'fixed-height' : 'default'}
                                        >
                                            {loading && <Loader />}
                                            <ReactStrapTable
                                                id='invoicenoteslist'
                                                data={tabledata}
                                                columns={maincol}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Formik>
                    </div>
                </CardBody>
            </Card>
        </Container>
    );
};

export default InvoiceNotes;